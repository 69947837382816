import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-top"
export default class extends Controller {
  connect () {
    window.addEventListener("scroll", function(){
      let scroll = document.querySelector('.scroll-to-top');
      scroll.classList.toggle("active", window.scrollY > 500)
    })
  }
}
