import { Controller } from "@hotwired/stimulus"

const body = document.body;
const scrollUp = "d-none";
const scrollDown = "d-block";
let lastScroll = 0;

// Connects to data-controller="sticky-navbar"
export default class extends Controller {
  initialize () {
    this.scroll = this.scrollHandler.bind(this)
  }

  connect () {
    document.addEventListener('scroll', this.scroll)
  }

  disconnect () {
    document.removeEventListener('scroll', this.scroll)
  }

  scrollHandler (event) {
    console.log(body.classList.contains('d-block'));

    const currentScroll = window.pageYOffset;
    if (currentScroll <= 100) {
      this.element.classList.remove(scrollDown);
      this.element.classList.add(scrollUp);

      return;
    }

    if (currentScroll > lastScroll && !this.element.classList.contains(scrollDown)) {
      this.element.classList.remove(scrollUp);
      this.element.classList.add(scrollDown);

    } else if (currentScroll < lastScroll && this.element.classList.contains(scrollDown)) {

      this.element.classList.remove(scrollDown);
      this.element.classList.add(scrollUp);
    }
    lastScroll = currentScroll;

  }
}
